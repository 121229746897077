import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Extremism`}</h1>
    <ul>
      <li parentName="ul">{`Many people who are considering using `}<a parentName="li" {...{
          "href": "/Polis",
          "title": "Polis"
        }}>{`Polis`}</a>{` are worried about "extreme opinions".`}</li>
      <li parentName="ul">{`Yes, people will make all sorts of `}<a parentName="li" {...{
          "href": "/comments",
          "title": "comments"
        }}>{`comments`}</a>{` in your conversation.`}</li>
      <li parentName="ul">{`A part of the `}<a parentName="li" {...{
          "href": "/Polis",
          "title": "Polis"
        }}>{`Polis`}</a>{` process and analysis is, in effect, intentioned to find out whether given opinions are so extreme — if it turns out half a population agrees with a `}<a parentName="li" {...{
          "href": "/comment",
          "title": "comment"
        }}>{`comment`}</a>{`, whose to define it that way?`}</li>
      <li parentName="ul">{`It's valuable to have so called "extreme" populations show up. They tend to produce a lot of data, and they tend to turn out to `}<a parentName="li" {...{
          "href": "/agree",
          "title": "agree"
        }}>{`agree`}</a>{` with lots of other statements. This is healing, and possible, and happens all the time in `}<a parentName="li" {...{
          "href": "/conversations",
          "title": "conversations"
        }}>{`conversations`}</a>{`.`}</li>
      <li parentName="ul">{`Do not moderate out what you as the moderator view as extreme opinions unless they are abusive, hateful, etc. They are very useful signal.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      